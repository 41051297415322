<template>
    <div class="mt-2 link-sharing-main">
        <div class="content-link-sharing-header">
            <MemberChangeDropdown :page="page" @action="goToPage" :currentClientId="currentClientId" @back="back()"/>
            <div class="block-search-link" v-if="webLinkListCopy.length > 0">
                <b-form-input id="search-link" type="text" placeholder="Search added Links ..." class="search-field-input"
                 v-model="searchLink" @input="changeSearchValue($event)" @keyup.enter="searchWebLink()"></b-form-input>

                <span v-if="!!searchLink" class="material-symbols-outlined clear-search-icon" :style="{ top: !!searchLink && isSearchTriggered ? '78px' : '88px'}" role="button" @click="clearSearch()" title="Clear Search">close</span>
                <span class="material-icons-outlined search-icon" :style="{ top: !!searchLink && isSearchTriggered ? '78px' : '88px'}" role="button" @click="searchWebLink()" title="Click here to search">search</span>
                <span v-if="!!searchLink && isSearchTriggered" class="stats-search-result">{{ webLinkList.length }} / {{ webLinkListCopy.length }} search result(s) found</span>
            </div>
        </div>
        
        <div class="d-flex justify-content-between mt-5 content-link-sharing">
            <div class="add-link-sharing">
                <ValidationObserver ref="form">
                    <form>
                        <div class="heading">Add New Links</div>
                        <Input
                            label="Insert New Link"
                            type="text"
                            name="link"
                            id="link"
                            class="fullwidth block field-link"
                            inputClass="add-link-form-field"
                            placeholder="Please insert link"
                            v-model="linkInfo.link"
                            labelOptionalText="(Example- http://www.mobilityexchange.net.)"
                            labelOptionalTextClass="label-optional-text"
                            rules="ValidateUrl"
                            required
                        />
                        <TextArea
                            type="text"
                            id="description"
                            inputClass="add-link-form-field"
                            name="description"
                            :rows=3
                            :cols=50
                            :limit=200
                            label="Add a Description"
                            placeholder="Add Description"
                            v-model="linkInfo.description"
                        />
                        <button class="primary text-button rounded-3" @click.prevent="addLink()">
                            <span class="rounded-add-custom material-icons-outlined">add</span>
                            <span class="ms-2">Add Link</span>
                        </button>
                    </form>
                </ValidationObserver>
            </div>
            <div class="link-sharing-listing" :class="webLinkList.length === 0 ? 'apply-no-links-style' : ''">
                <template v-if="webLinkList.length > 0">
                    <div v-for="webLink in webLinkList" :key="webLink.linkId" class="content-link-sharing-block">
                        <div class="link-sharing-block">
                            <span class="link-text mb-2" style="word-break: break-all;">{{ webLink.link }}</span>
                            <span class="text-link-description">{{ webLink.description }}</span>

                            <ejs-tooltip
                                class="tooltipcontainer"
                                content="Delete"
                                target="#target"
                                cssClass="customtooltip ms-5"
                                position="top"
                            >
                                <span id="target" class="material-symbols-outlined btn-delete-link" role="button" @click.prevent="deleteLink(webLink.linkId)">close</span>
                            </ejs-tooltip>
                            <span class="material-symbols-outlined btn-link" role="button" title="Click to open link" @click.prevent="openLink(webLink.link)">link</span>
                        </div>
                        <div class="vertical-connecting-line"></div>
                    </div>
                </template>
                <template v-else>
                    <div class="d-flex justify-content-center align-items-center no-links-block">{{ !foundFilterSearch ? 'No links found based on your search' : 'No Links Added.' }}</div>
                </template>
            </div>
        </div>
        <!-- ### Link Delete Alert Warning Popup ##### -->
        <div class="link-delete-modal">
            <DeleteLinkAlert
                v-if="showLinkDeleteAlert"
                :open="showLinkDeleteAlert"
                @cancel="showLinkDeleteAlert = false"
                @handleDelete="handleDeleteLink()">
            </DeleteLinkAlert>
        </div>
        <Loading v-if="showLoader" />
    </div>
</template>

<script>
import MemberChangeDropdown from '@/components/general/dropdownButtons/MemberChangeDropdown.vue';
import { ValidationObserver } from 'vee-validate';
import { clients } from '@/util/apiRequests';
import { contactDetails } from './../../util/helpers/graphql/common.graphql';
import DeleteLinkAlert from '@/components/client-manager/LinkSharing/DeleteLinkAlert.vue';
import { fetchWebLinks, createNewLink, deleteLinkById } from './../../util/helpers/graphql/linkSharing.graphql';
import Loading from '@/components/general/loading/loading.vue';

const initialLinkInfoState = {
    link: '',
    description: ''
};

export default {
    name: 'LinkSharing',
    components: {
        MemberChangeDropdown,
        ValidationObserver,
        DeleteLinkAlert,
        Loading
    },
    data() {
        return {
            page: {
                label: 'Link Sharing',
                name: 'Link Sharing',
            },
            currentClientId: this.$route.params.client_id,
            clientData: null,
            contactDetails: {
                familyId: null,
                contactId: null
            },
            webLinkList: [],
            webLinkListCopy: [],
            selectedLinkIdToDelete: null,
            linkInfo: {
                ...initialLinkInfoState
            },
            searchLink: '',
            foundFilterSearch: true,
            isSearchTriggered: false,
            showLinkDeleteAlert: false,
            showLoader: false
        }
    },
    methods: {
        goToPage(memberChangeResponse) {
            this.$router.push(memberChangeResponse.page);
            this.currentClientId = memberChangeResponse.page.params.client_id;
            this.init();
        },
        back() {
            const page = {
                label: "ClientOverview",
                name: "ClientOverview", "params": { "record_id": this.currentClientId }
            }
            this.$router.push(page);
        },
        changeSearchValue(value) {
            if (!value) {
                this.clearSearch();
            }
        },
        // Search web link from listed link
        searchWebLink() {
            if (this.searchLink) {
                let searchKeyword = this.searchLink.toString().toLowerCase();
            
                this.webLinkList = this.webLinkListCopy.filter(webLinkObj => Object.values(webLinkObj)
                .map(webLinkElem => webLinkElem?.toString()?.toLowerCase()).some(el => el?.includes(searchKeyword)));
           
                this.foundFilterSearch = this.webLinkList.length > 0 ? true : false;
                this.isSearchTriggered = true;
            } else {
                this.clearSearch();
            }
        },
        // Clear search
        clearSearch() {
            this.searchLink = '';
            this.webLinkList = this.webLinkListCopy;
            this.isSearchTriggered = false;
        },
        // Get/Fetch particular client detail
        async getClient() {
            try {
                const endpoint = clients.getClient(this.currentClientId);
                const clientDetailResponse = await this.$api.get(endpoint);
                if (!clientDetailResponse.data) {
                    throw 'Failed';
                }
                if (clientDetailResponse.data.length === 0) { throw 'Info_Not_Found'; }
                this.clientData = clientDetailResponse.data[0];

            } catch (e) {
                const errorMessage = (e === 'Info_Not_Found') ? 'Client information not found !' : 'Failed to get client information !';
                throw errorMessage;
            }
        },
        // Get/Fetch client contact details
        async getContactDetails() {
            try {
                const contactDetailResponse = await contactDetails(this.clientData.email);
                this.contactDetails = {
                    familyId: contactDetailResponse.familyId,
                    contactId: contactDetailResponse.contactId
                }

            } catch(e) {
                const errorMessage = e ? e.errors ? e.errors[0]?.message ? e.errors[0].message : e : e : 'Failed to get client contact details !';
                throw errorMessage;
            }
        },
        // Get/Fetch added/shared web links
        async getWebLinks(familyId) {
            try {
                let tempWebLinkList = [];
                const webLinksResponse = await fetchWebLinks(familyId);
                tempWebLinkList = webLinksResponse?.getLinks.items;

                // Sorting web link list based createdAt in descending order, so that latest added / shared link appeared at top
                if (tempWebLinkList.length > 0) {
                    this.webLinkList = tempWebLinkList.sort((a, b) => b?.createdAt - a?.createdAt);
                } else {
                    this.webLinkList = [];
                }
                this.webLinkListCopy = [ ...this.webLinkList ];
              
            } catch(e) {
                let errorMessage = '';
                if (e.errors[0].message === 'Logged user is not in this family.') {
                    errorMessage = "You are not assigned as Counselor for this member. Hence link cannot be fetched.";
                } else {
                    errorMessage = e ? e.errors ? e.errors[0]?.message ? e.errors[0].message : e : e : 'Failed to fetch web link !';
                }
                throw errorMessage;
            }
        },
        // Initialize needed data / states on load
        async init() {
            try {
                this.showLoader = true;
                this.resetAll();

                await this.getClient();
                await this.getContactDetails();
                await this.getWebLinks(this.contactDetails.familyId);

            } catch(e) {
                this.$toasted.error(e);
            } finally {
                this.showLoader = false;
            }
        },
        // Format form fields values to be formatted before save
        formatNeededFormValues() {
            let linkValue = '';

            if ((this.linkInfo.link).substring(0, 8) == 'https://') {
                linkValue = this.linkInfo.link;
            } else if (this.linkInfo.link.substring(0, 7) == 'http://') {
                linkValue = 'https://' + this.linkInfo.link.replace('http://', '');
            } else {
                linkValue = 'https://' + this.linkInfo.link;
            }

            if (linkValue.includes('www.')) {
                linkValue = linkValue.replace('www.', ''); 
            }

            this.linkInfo.link = linkValue;
        },
        // Add new link / submit add new link form
        async addLink() {
            try {
                const form = this.$refs.form;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }
                // if (this.webLinkList.length === 10) {
                //     this.$toasted.error("Can't add more links !");
                //     this.resetForm();
                //     return;
                // }

                this.showLoader = true;
                this.formatNeededFormValues();
       
                await createNewLink(this.contactDetails.familyId, this.linkInfo.link, this.linkInfo.description);
                this.$toasted.success('Link added successfully !');
                this.resetForm();

                setTimeout(async () => {
                    await this.getWebLinks(this.contactDetails.familyId);
                }, 1000);
                
            } catch(e) {
                let errorMessage = '';
                if (e.errors[0].message === 'Logged user is not in this family.') {
                    errorMessage = "You are not assigned as Counselor for this member. Hence you can't add Links.";
                } else {
                    errorMessage = e ? e.errors ? e.errors[0]?.message ? e.errors[0].message : e : `Failed to fetch web links, ${e}` : 'Failed to add link !';
                }
                this.$toasted.error(errorMessage);
            } finally {
                this.showLoader = false;
            }
        },
        // Triggers on click delete button
        deleteLink(linkId) {
            if (!linkId) {
                this.$toasted.error( `Link id not found !` );
                return;
            }
            this.selectedLinkIdToDelete = linkId;
            this.showLinkDeleteAlert = true;
        },
        // Delete handler for particular web link after confirmation "YES" from delete alert dialog
        async handleDeleteLink() {
            try {
                this.showLinkDeleteAlert = false;
                this.showLoader = true;

                await deleteLinkById(this.contactDetails.familyId, this.selectedLinkIdToDelete);
                this.$toasted.success('Link deleted successfully !');

                setTimeout(async () => {
                    await this.getWebLinks(this.contactDetails.familyId);
                }, 1000);
                
            } catch(e) {
                let errorMessage = '';
                if (e.errors[0].message === 'Logged user is not in this family.') {
                    errorMessage = "You are not assigned as Counselor for this member. Hence you can't delete Links.";
                } else {
                    errorMessage = e ? e.errors ? e.errors[0]?.message ? e.errors[0].message : e : e : 'Failed to delete link !';
                }
                this.$toasted.error(errorMessage);
            } finally {
                this.showLoader = false;
            }
        },
        // Open web link in new tab on click link icon
        openLink(link) {
            window.open(link, '_blank');
        },
        // Reset form fields / state
        resetForm() {
            this.linkInfo = { ...initialLinkInfoState };
            requestAnimationFrame(() => {
                this.$refs.form.reset();
            });
        },
        // Reset all link sharing pages states
        resetAll() {
            this.resetForm();
            this.webLinkList = [];
            this.webLinkListCopy = [];
        }
    },
    created() {
        this.init();
    }
}
</script>

<style scoped>
    /* width */
    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #ccc3c3;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--base-theme-color);
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--base-theme-color);
    }
</style>